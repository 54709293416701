
.logoContainer {
  display: flex;
  flex-direction: row;
}

.logoIcon {
  --hovercolor: white;
  color: #cdd6f4;

  margin: 5px;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  cursor: pointer;

  position: relative;
  display: inline-block;
  transition: box-shadow 0.2s;

  & .tooltip {
    z-index: 8;
    position: absolute;
    opacity: 0;
    transition: opacity 0.4s;

    background-color: #cdd6f4;
    color: #11111b;
    box-shadow: 2px 2px 10px #11111b;

    top: 74px;
    left: 32px;
    width: 100px;
    padding: 5px 0;
    margin-left: -50px;
    border-radius: 5px;

    text-align: center;
  }
}

.logoIcon:visited {
  color: #cdd6f4;
}
.logoIcon:link {
  color: #cdd6f4;
}
.logoIcon:active {
  color: var(--hovercolor);
}
.logoIcon:hover {
  color: var(--hovercolor);
  box-shadow: 2px 2px 10px #11111b;
  & .tooltip {
    opacity: 1;
    transition: opacity 0.2s;
  }
}

& .github {
  --hovercolor: #a6e3a1;
}
& .osu {
  --hovercolor: #f5c2e7;
}
& .steam {
  --hovercolor: #89dceb;
}
& .codeforces {
  --hovercolor: #eba0ac;
}
& .discord {
  --hovercolor: #cba6f7;
}
