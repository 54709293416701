.topBar {
  z-index: 5;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 54px;
  background-color: #1e1e2e;
}

.homeButton {
  width: 24px;
  height: 24px;
  padding: 15px;
  border-radius: 0 0 20px 0;

  position: fixed;
  left: 0;
  top: 0;

  cursor: pointer;

  z-index: 10;

  & span {
    user-select: none;
  }
}
.homeButton:hover {
  color: #11111b;
  background-color: #f38ba8;
}

.menuButton {
  width: 24px;
  height: 24px;
  padding: 15px;
  border-radius: 0 0 0 20px;

  position: fixed;
  top: 0;
  right: 0;

  cursor: pointer;

  z-index: 10;

  & span {
    user-select: none;
  }
}
.menuButton:hover {
  color: #11111b;
  background-color: #cba6f7;
}

.navBar {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  background-color: #313244;

  clip-path: circle(200% at top right);
  transition: clip-path 0.5s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  & .navBarItemContainer {
    width: 60%;

    & .navBarItem {
      --hovercolor: #cdd6f4;
      font-size: 4em;
      margin: 0.1em;
      padding: 0.2em;
      width: max-content;
      cursor: pointer;
      position: relative;
    }
    & .navBarItem:hover {
      color: var(--hovercolor);
    }

    & .navBarItem::after {
      content: "";

      position: absolute;
      left: 50%;
      bottom: 0;

      height: 3px;
      width: 0;
      transition: 0.2s;

      background-color: var(--hovercolor);
    }
    & .navBarItem:hover::after {
      left: 0;
      width: 100%;
      box-shadow: 0 0 15px var(--hovercolor);
    }

    & .navBarItem:nth-child(1) {
      --hovercolor: #eba0ac;
    }
    & .navBarItem:nth-child(2) {
      --hovercolor: #fab387;
    }
    & .navBarItem:nth-child(3) {
      --hovercolor: #f9e2af;
    }
    & .navBarItem:nth-child(4) {
      --hovercolor: #a6e3a1;
    }
    & .navBarItem:nth-child(5) {
      --hovercolor: #89dceb;
    }
  }
}
.navBar.hidden {
  clip-path: circle(0 at top right);
}

a {
  text-decoration: none;
}
a:link {
  color: #89b4fa;
}
a:visited {
  color: #cba6f7;
}
a:hover {
  text-decoration: underline;
}

html {
  scroll-snap-type: y mandatory;
}

.italics {
  font-style: italic;
}

.scrollSection {
  z-index: 0;
  margin: 44px 0;
  width: 100vw;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-content: center;
  align-items: center;

  text-wrap: break-word;

  & .sectionContent {
    z-index: 2;
    margin: 15px;
    max-width: 100vw;
    display: inline-block;
  }
}

.screenSection {
  z-index: 0;
  width: 100vw;
  height: 100vh;

  scroll-snap-align: start;
  scroll-snap-stop: always;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom: 5px solid #11111b;
  overflow-y: hidden;

  & .sectionContent {
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    align-content: center;

    & img {
      width: 40%;
      max-height: 600px;
      margin: 30px;
      border-radius: 0 80px 0 80px;
      box-shadow: 0px 0px 10px #11111b;
      transition: box-shadow 0.2s, transform 0.2s;
      z-index: 2;
    }

    & img:hover {
      box-shadow: 10px 10px 10px #11111b;
      transform: scale(1.01) rotate(0.5deg);
    }
  }
}

.screenSection:last-child {
  border-bottom: 0;
}


.downArrow {
  width: 24px;
  height: 24px;

  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -12px;

  & span {
    user-select: none;
  }
}


@media screen and (max-width:900px) {
  .scrollSection {
  }
  .screenSection {
    flex-direction: column;
    & .sectionContent {
      flex-direction: column;
      & img {
        width: 90%;
        max-width: 500px;
        margin: 0;
        border-radius: 0 40px 0 40px;
      }
    }
  }
  .navBar {
    transition: clip-path 0s;
    & .navBarItemContainer {
      width: 90%;
      & .navBarItem {
        font-size: 3em;
      }
      & .navBarItem::after {
        transition: 0s;
      }
    }
  }
}
