.introText {
  text-align: center;
  font-size: 4em;
  z-index: 2;
}

.infoText {

  padding: 30px;
  font-size: 2em;
  text-wrap: break-word;
  max-width: 100vw;
  z-index: 2;

  & h1 {
    margin: 10px 0;
  }

  & p {
    margin: 0;
    padding: 0;
  }
  & p:nth-child(1) {
    & span {
      color: #eba0ac;
    }
  }
  & p:nth-child(2) {
    & span {
      color: #fab387;
    }
  }
  & p:nth-child(3) {
    & span {
      color: #f9e2af;
    }
  }
  & p:nth-child(4) {
    & span {
      color: #a6e3a1;
    }
  }
  & p:nth-child(5) {
    & span {
      color: #89dceb;
    }
  }
}

@media screen and (max-width:900px) {
  .introText {
    font-size: 2em;
  }
  .infoText {
    font-size: 1.5em;
  }
}
