.projectCard {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-content: center;
  align-items: center;

  min-width: 600px;
  max-width: 45vw;
  margin: 10px;
  background-color: rgba(24, 24, 37, 0.5);
  padding: 10px;

  border-radius: 10px;
  
  & img {
    flex: 0 0 300;
    width: 300px;
    border-radius: 5px;
  }
  & .projectText {
    margin-left: 40px;
  }
}

@media screen and (max-width:900px) {
  & .projectCard {
    margin: 10px 0;
    flex-direction: column;
    min-width: 0;
    max-width: 100vw;
    & .projectText {
      margin-left: 0;
    }
  }
}
