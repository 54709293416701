
.interestContent {
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  & div.sectionText {
    flex: 2 2 600px;
  }

  & figure {
    flex: 1 1 200px;
    margin: 0 0 0 20px;
    padding: 0;
    & img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

@media screen and (max-width:1200px) {
  .interestContent {
    flex-direction: column;
    justify-content: left;
    flex-wrap: wrap;
    width: 95%;
    & div.sectionText {
      max-width: 95%;
      flex: none;
    }

    & figure {
      flex: 0 0 300px;
      margin: 10px 0 0 0;
      & img {
        object-fit: cover;
        max-height: 300px;
        border-radius: 5px;
      }
    }
  }
}
